<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card v-for="bid in bids" :key="`bid_${bid.id}`">
        <ValidationObserver ref="addEmoji">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors }"
              class="md-layout-item md-size-100"
              mode="passive"
              tag="div"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Игра</label>
                <md-select v-model="bid.typeGame">
                  <md-option
                    v-for="game in games"
                    :key="`option_role_${game.id}`"
                    :value="game.id"
                  >
                    {{ game.title }}
                  </md-option>
                </md-select>
              </md-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              class="md-layout-item md-size-100"
              mode="passive"
              tag="div"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Ставка</label>
                <md-input v-model="bid.betAmount" type="number" />
              </md-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              class="md-layout-item md-size-100"
              mode="passive"
              tag="div"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Валюта</label>
                <md-select v-model="bid.currencyType">
                  <md-option
                    v-for="currency in currencies"
                    :key="`option_role_${currency.id}`"
                    :value="currency.id"
                  >
                    {{ currency.title }}
                  </md-option>
                </md-select>
              </md-field>
            </ValidationProvider>
            <div class="buttons-wrap justify-end">
              <md-button class="md-raised md-danger" @click="handleDelete(bid)">
                Удалить
              </md-button>
              <md-button
                class="md-raised md-primary"
                @click="handleUpdate(bid)"
              >
                Обновить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
      <div class="buttons-wrap justify-center">
        <md-button class="md-raised md-success" @click="handleCreate">
          Создать новую ставку
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      till: 0,
      from: 0,
      step: 0,
      currencies: [
        {
          id: 1,
          title: "Серебро",
        },
        {
          id: 2,
          title: "Золото",
        },
      ],
      games: [
        {
          id: 0,
          title: "Дурак",
        },
        {
          id: 1,
          title: "Слоты",
        },
        {
          id: 2,
          title: "Зарики",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      bids: (state) => state.settings.bids,
    }),
  },
  mounted() {
    this.getBids();
  },
  methods: {
    ...mapActions(["getBids", "createBid", "updateBid", "deleteBid"]),
    async handleCreate() {
      const response = await this.createBid();

      this.$notify({
        message: `Ставка${response ? "" : " не"} создана.`,
        icon: response ? "task_alt" : "do_not_disturb_alt",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: response ? "primary" : "secondary",
      });
      if (response) {
        this.getBids();
      }
    },
    async handleUpdate(bid) {
      if (bid.betAmount > 0) {
        const response = await this.updateBid(bid);

        this.$notify({
          message: `Ставка${response ? "" : " не"} обновлена.`,
          icon: response ? "task_alt" : "do_not_disturb_alt",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: response ? "primary" : "secondary",
        });
        if (response) {
          this.getBids();
        }
      } else {
        this.$notify({
          message: "Ставка должна быть больше 0.",
          icon: "do_not_disturb_alt",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "secondary",
        });
      }
    },
    async handleDelete(bid) {
      const response = await this.deleteBid(bid);

      this.$notify({
        message: `Ставка${response ? "" : " не"} удалена.`,
        icon: response ? "task_alt" : "do_not_disturb_alt",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: response ? "primary" : "secondary",
      });
      if (response) {
        this.getBids();
      }
    },
  },
};
</script>

<style scoped>
.buttons-wrap {
  display: flex;
  align-items: center;
}

.spin {
  margin-left: 20px;
}

.cancel-btn {
  margin-left: 10px !important;
}
.m-0 {
  margin: 0 !important;
}

.xmx-datepicker,
.w-full {
  width: 100%;
}
</style>
